import { callApiWithToken } from "../utils/http_common";

const createOrder = ({ type }) => {
    return callApiWithToken.post(`/order/?type=${type}`)
}

const createPrefundOrder = (body) => {
    return callApiWithToken.post(`/order/prefundOrder`, { ...body })
}

const createCollectionPayment = (body) => {
    return callApiWithToken.post(`/order/collectionsPayment`, { ...body })
}

const createCollectionOrder = (body) => {
    return callApiWithToken.post(`/order/collectionOrder`, { ...body })
}

const sendPaymentLink = (body) => {
    return callApiWithToken.post(`/order/collectionOrder/sendMail`, { ...body })
}
const getOrder = ({ filters }) => {
    return callApiWithToken.get(`/order/`, {
        params: {
            filters
        }
    })
}

const resetOrder = ({ orderId }) => {
    return callApiWithToken.post(`/order/${orderId}/reset`)
}

const getConfigs = ({ product }) => {
    return callApiWithToken.get(`/configuration/${product}`);
}

const cancelOrder = ({ orderId }) => {
    return callApiWithToken.post(`/order/${orderId}/cancel`);
}

const getOrderQuotes = ({ orderId, type }) => {
    return callApiWithToken.get(`/order/quotes?orderId=${orderId}&type=${type}`);
}

const update = ({ orderId, type }) => {
    return callApiWithToken.get(`/order/update?orderId=${orderId}&type=${type}`);
}

const continueOrder = ({ orderId, paymentCode, fiatDetails }) => {

    return callApiWithToken.post(`/order/continue?orderId=${orderId}&paymentCode=${paymentCode}`);
}

const getOrderStatus = ({ orderId }) => {
    return callApiWithToken.get(`/order/status?orderId=${orderId}`);
}
const getOrderSummary = ({ orderId }) => {
    return callApiWithToken.get(`/order/orderSummary/${orderId}`);
}
const getCollectionOrderSummary = ({ orderId }) => {
    return callApiWithToken.get(`/order/collectionOrderSummary/${orderId}`);
}

const getSettlementData = ({ filters }) => {
    return callApiWithToken.get(`/settlement/`, {
        params: {
            filters
        }
    })
}
const getRampOrder = ( body ) => {
    return callApiWithToken.post(`/order/list/`, {...body })
}


const OrderService = {
    createOrder,
    getOrder,
    resetOrder,
    getConfigs,
    cancelOrder,
    getOrderQuotes,
    update,
    continueOrder,
    getOrderStatus,
    getSettlementData,
    createPrefundOrder,
    getOrderSummary,
    getCollectionOrderSummary,
    createCollectionOrder,
    sendPaymentLink,
    createCollectionPayment,
    getRampOrder
}

export default OrderService