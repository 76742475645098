import { Badge, Stack, Box, HStack, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { BiReset } from "react-icons/bi";
import { isEmpty, get, round } from "lodash";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Loader from "../../../components/Loader";
import OrderService from "../../../services/createOrderService";
import GridV2 from "../../../utils/agTablesV2";
import { TimezoneFormatter } from "../../../utils/formatter";
import { getDataFromSession } from "../../../utils/session-storing-utils";
import { colorMap, onRampStatusMap } from "../../../constants";

const RampOrderV2 = (props) => {
  const [refreshGrid, setRefreshGrid] = useState(false);
  const isLoading = useSelector((state) => state?.order?.loading);
  const orgId = useSelector((state) => state?.login?.orgDetails?.orgId);
  const numberFormatter = (value) => {
    const result = parseFloat(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 8,
    });
    return result === "NaN" ? "0.00" : result;
  };
  const { pathname } = window.location;
  const rampType = pathname.split("/").pop() === "onramp-order" ? "buy" : "sell";
  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var cellDate = new Date(cellValue);
      if (
        filterLocalDateAtMidnight.toLocaleDateString() ===
        cellDate.toLocaleDateString()
      ) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
  };

  const handleReset = () => {
    setRefreshGrid(!refreshGrid);
  };

  const getOrderData = async (filters) => {
    filters["orgId"] = orgId;
    const timezone =
      getDataFromSession("connectionData", "timezone") || moment.tz.guess();
    const filter = {
      orgId: orgId,
    };    
    let rampFilter = {
      type: rampType,
      preferredTz: timezone,
      clientTz: moment.tz.guess(),
    };
    const defaultDate = {
      startDate: moment().subtract(2, "months"),
      endDate: moment().format("YYYY-MM-DD 23:59:59"),
    };
    const limit = filters?.endRow - filters?.startRow;
    const page = filters?.endRow / limit - 1;
    rampFilter.filters = {
      dateRange: defaultDate,
      filterModel: filters?.filterModel ?? [],
    };
    rampFilter.limit = limit;
    rampFilter.page = page;
    rampFilter.orgId = orgId;
    rampFilter.sortModel = filters?.sortModel;
    rampFilter.filter = filter;
    const responseData = await OrderService.getRampOrder({ ...rampFilter });
    return responseData;
  };

  const onRampColumns = [
    {
      headerName: "Order ID",
      field: "orderId",
      width: "200",
      customerVisibility: true,
      colId: "orderId",
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      cellRenderer: ({ value }) => {
        return (
          <Link
            style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: "14px" }}
          >
            {value}
          </Link>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      width: "230",
      colId: "status",
      customerVisibility: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      cellRenderer: (props) => {
        const newStatus = onRampStatusMap[props.data?.status] || props.data?.status;
        const color = colorMap[props.data?.status];
        return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
      },
    },
    {
      headerName: "User ID / Email",
      field: "email",
      width: "230",
      colId: "email",
      cellRenderer: ({ value }) => {
        return value || "N/A";
      },
      customerVisibility: true,
    },
    {
      headerName: "Date Order Initiated",
      field: "createdAt",
      filterParams: filterParams,
      colId: "createdAt",
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: "date",
      width: "220",
      filter: "agDateColumnFilter",
      cellRenderer: ({ value }) => {
        return moment(value).format("DD-MMM-YYYY");
      },
      color: "info",
    },
    {
      headerName: "Time Order Initiated",
      field: "createdAt",
      width: "220",
      colId: "createdAt",
      customerVisibility: true,
      cellRenderer: ({ value }) => {
        return TimezoneFormatter(value, "LT", "America/Detroit");
      },
      color: "info",
    },
    {
      headerName: "Date Completed",
      field: "timestamps.assetSettledAt",
      width: "180",
      colId: "timestamps.assetSettledAt",
      cellRenderer: (params) => {
        if(params.data?.timestamps?.assetSettledAt){
          return moment(params.data?.timestamps?.assetSettledAt).format("DD-MMM-YYYY");
        }else if(params.data?.timestamps?.fundSettledAt){
          return moment(params.data?.timestamps?.fundSettledAt).format("DD-MMM-YYYY");
        }else{
          return "NA";
        }
      },
      color: "info",
      customerVisibility: true,
    },
    {
      headerName: "Time Completed",
      field: "timestamps.assetSettledAt",
      width: "180",
      colId: "timestamps.assetSettledAt",
      cellRenderer: (params) => {
        if(params.data?.timestamps?.assetSettledAt){
          return TimezoneFormatter(params.data?.timestamps?.assetSettledAt, "LT", "America/Detroit");
        }else if(params.data?.timestamps?.fundSettledAt){
          return TimezoneFormatter(params.data?.timestamps?.fundSettledAt, "LT", "America/Detroit");
        }else{
          return "NA";
        }
      },
      color: "info",
      customerVisibility: true,
    },
    {
      headerName: "Fiat Payment Method",
      width: "150",
      color: "warning",
      colId: "fiat.paymentName",
      cellRenderer: (params) => {
        if (!isEmpty(params.data?.paymentType)) {
          return params.data?.paymentType.replace(/_/g, " ").replace(/^./, match => match.toUpperCase());
        }else {
          return params.data?.fiat?.paymentName;
        }
      },
      customerVisibility: true,
    },
    {
      headerName: "Fiat Currency",
      width: "150",
      color: "warning",
      colId: "fiat.currency",
      cellRenderer: (params) => {
        if (!isEmpty(params.data?.fiat?.currency)) {
          return params.data?.fiat?.currency;
        } else {
          return params.data?.fiatTicker;
        }
      },
      customerVisibility: true,
    },
    {
      headerName: "Gross Fiat Amount (Received from the user)",
      width: "230",
      color: "warning",
      colId: "fiat.orderAmount",
      cellRenderer: (params) => {
        if (!isEmpty(params.data?.fiat?.orderAmount)) {
          return numberFormatter(params.data?.fiat?.orderAmount);
        } else {
          return numberFormatter(params.data?.fiatAmount);
        }
      },
      customerVisibility: true,
    },
    {
      headerName: "Processing fees %",
      width: "150",
      colId: "fiat.processingRate",
      cellRenderer: (params) => {
        if(params.data?.fiat?.processingRate){
          const processingFee = Number(
            params.data?.fiat?.processingRate
          ).toFixed(2);
          return numberFormatter(processingFee) + "%";
        }else if(params.data?.fees?.processingFee){
          return Number((params.data?.fees?.processingFee/params.data?.fiat?.orderAmount)*100).toFixed(2) + "%";
        }
        else{
          return "NA";
        }
          
      },
      color: "warning",
      customerVisibility: true,
    },
    {
      headerName: "Processing fees amt.",
      width: "180",
      colId: "fiat.processingFee",
      cellRenderer: (params) => {
        if(params.data?.fees?.processingFee){
          return numberFormatter(params.data?.fees?.processingFee);
        } 
       else if (isEmpty(params.data?.fiat?.processingFee)) {
          const tfFee = Number(get(params.data, "fiat.tfFee", 0));
          const cxFee = Number(get(params.data, "fiat.cxFee", 0));
          const processingFee = (tfFee + cxFee).toFixed(2);
          return numberFormatter(processingFee);
        } 
        else {
          const processingFee = Number(
            params.data?.fiat?.processingFee
          ).toFixed(2);
          return numberFormatter(processingFee);
        }
      },
      color: "warning",
      customerVisibility: true,
    },
    {
      headerName: "Discount %",
      width: "180",
      colId: "fiat.discount",
      cellRenderer: (params) => {
        const discountRate = Number(get(params.data, "fiat.discountRate", 0));
        return numberFormatter(discountRate) + "%";
      },
      color: "warning",
      customerVisibility: true,
    },
    {
      headerName: "Discount amt.",
      width: "180",
      colId: "fiat.discount",
      cellRenderer: (params) => {
        const discountRate = Number(get(params.data, "fiat.discount", 0));
        return numberFormatter(discountRate);
      },
      color: "warning",
      customerVisibility: true,
    },
    {
      headerName: "Customer Rev share",
      width: "150",
      colId: "fiat.cxfee",
      cellRenderer: (params) => {
        const discountRate = Number(get(params.data, "fiat.cxfee", 0));
        return numberFormatter(discountRate);
      },
      color: "warning",
      customerVisibility: true,
    },
    {
      headerName: "Crypto Ticker",
      width: "150",
      colId: "crypto.currency",
      cellRenderer: (params) => {
        if (!isEmpty(params.data?.crypto?.currency)) {
          return params.data?.crypto?.currency ?? "NA";
        }else if(params.data?.cryptoTicker){
          return params.data?.cryptoTicker ?? "NA";
        }
         else {
          return  params.data?.settlementDetails?.settlementCryptoTicker ;
        }
      },
      color: "info",
      customerVisibility: true,
    },
    {
      headerName: "Crypto units Delivered",
      width: "150",
      colId: "cryptoAmount",
      cellRenderer: (params) => {
        if(params.data?.cryptoAmount){
          return params.data?.cryptoAmount ?? "NA";
        }else{
          return params.data?.withdrawAmount ?? "NA";
        }
      },
      color: "warning",
      customerVisibility: true,
    },
    {
      headerName: "Crypto Wallet Address",
      width: "300",
      color: "info",
      colId: "metaData.sendersWalletAddress",
      cellRenderer: (params) => {
        if(params.data?.walletAddress){
          return params.data?.walletAddress ?? "NA";
        }else{
          return params.data?.settlementDetails?.settlementWalletAddress ?? "NA";
        }
            
      },
      customerVisibility: true,
    },
  ];

  const offRampColumns = [
    {
      headerName: "Order ID",
      field: "orderId",
      width: "200",
      customerVisibility: true,
      colId: "orderId",
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      cellRenderer: ({ value }) => {
        return (
          <Link
            style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: "14px" }}
          >
            {value}
          </Link>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      width: "230",
      colId: "status",
      customerVisibility: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      cellRenderer: (props) => {
        const newStatus = onRampStatusMap[props.data?.status] || props.data?.status;
        const color = colorMap[props.data?.status];
        return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
      },
    },
    {
      headerName: "User ID / Email",
      field: "email",
      width: "230",
      colId: "email",
      cellRenderer: ({ value }) => {
        return value ?? "N/A";
      },
      customerVisibility: true,
    },
    {
      headerName: "Date Order Initiated",
      field: "createdAt",
      filterParams: filterParams,
      colId: "createdAt",
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: "date",
      width: "220",
      filter: "agDateColumnFilter",
      cellRenderer: ({ value }) => {
        return moment(value).format("DD-MMM-YYYY");
      },
      color: "info",
    },
    {
      headerName: "Time Order Initiated",
      field: "createdAt",
      width: "220",
      colId: "createdAt",
      customerVisibility: true,
      cellRenderer: ({ value }) => {
        return TimezoneFormatter(value, "LT", "America/Detroit");
      },
      color: "info",
    },
    {
      headerName: "Date Completed",
      field: "timestamps.assetSettledAt",
      width: "180",
      colId: "timestamps.assetSettledAt",
      cellRenderer: (params) => {
        if(params.data?.timestamps?.assetSettledAt){
          return moment(params.data?.timestamps?.assetSettledAt).format("DD-MMM-YYYY");
        }else if(params.data?.timestamps?.fundSettledAt){
          return moment(params.data?.timestamps?.fundSettledAt).format("DD-MMM-YYYY");
        }else{
          return "NA";
        }
      },
      color: "info",
      customerVisibility: true,
    },
    {
      headerName: "Time Completed",
      field: "timestamps.assetSettledAt",
      width: "180",
      colId: "timestamps.assetSettledAt",
      cellRenderer: (params) => {
        if(params.data?.timestamps?.assetSettledAt){
          return TimezoneFormatter(params.data?.timestamps?.assetSettledAt, "LT", "America/Detroit");
        }else if(params.data?.timestamps?.fundSettledAt){
          return TimezoneFormatter(params.data?.timestamps?.fundSettledAt, "LT", "America/Detroit");
        }else{
          return "NA";
        }
      },
      color: "info",
      customerVisibility: true,
    },
    {
      headerName: "Crypto Currency",
      width: "180",
      color: "warning",
      colId: "crypto.currency",
      cellRenderer: (params) => {
          return params.data?.crypto?.currency ?? "NA";
      },
      customerVisibility: true,
    },
    {
      headerName: "Crypto Sold by the user",
      width: "200",
      color: "warning",
      colId: "cryptoAmount",
      cellRenderer: (params) => {
          return params.data?.cryptoAmount;
      },
      customerVisibility: true,
    },
    {
      headerName: "Crypto Wallet Address",
      width: "300",
      color: "info",
      colId: "metaData.walletAddressData.addr",
      cellRenderer: (params) => {
            if(params.data?.metaData?.walletAddressData?.addr){
              return params.data?.metaData?.walletAddressData?.addr
            }
            else if(params.data?.walletAddress){
              return params.data?.walletAddress
            }else{
              return "NA";
            }
      },
      customerVisibility: true,
    },
    {
      headerName: "Fiat Payment Method",
      width: "180",
      color: "warning",
      colId: "fiat.paymentType",
      cellRenderer: (params) => {
        if (!isEmpty(params.data?.paymentType)) {
          return params.data?.paymentType.replace(/_/g, " ").replace(/^./, match => match.toUpperCase());
        } else {
          return params.data?.paymentName;
        }
      },
      customerVisibility: true,
    },
    {
      headerName: "Fiat Currency",
      width: "150",
      color: "warning",
      colId: "crypto.currency",
      cellRenderer: (params) => {
          return params.data?.fiatTicker ?? "NA";
      },
      customerVisibility: true,
    },
    {
      headerName: "Gross txn amount on fiat",
      width: "230",
      color: "warning",
      colId: "metaData.usdAmount",
      cellRenderer: (params) => {
          return numberFormatter(params.data?.fiatAmount) ?? "NA";
      },
      customerVisibility: true,
    },
    {
      headerName: "Processing fees %",
      width: "150",
      colId: "fiat.processingRate",
      cellRenderer: (params) => {
        if(params.data?.fiat?.processingRate){
          const processingFee = Number(
            params.data?.fiat?.processingRate
          ).toFixed(2);
          return numberFormatter(processingFee) + "%";
        }else if(params.data?.fees?.processingFee){
          return Number((params.data?.fees?.processingFee/params.data?.fiatAmount)*100).toFixed(2) + "%";
        }
        else{
          return "NA";
        }
      },
      color: "warning",
      customerVisibility: true,
    },
    {
      headerName: "Processing fees amt.",
      width: "180",
      colId: "fiat.processingFee",
      cellRenderer: (params) => {
        if(params.data?.fees?.processingFee){
          return numberFormatter(params.data?.fees?.processingFee);
        } 
       else if (isEmpty(params.data?.fiat?.processingFee)) {
          const tfFee = Number(get(params.data, "fiat.tfFee", 0));
          const cxFee = Number(get(params.data, "fiat.cxFee", 0));
          const processingFee = (tfFee + cxFee).toFixed(2);
          return numberFormatter(processingFee);
        } 
        else {
          const processingFee = Number(
            params.data?.fiat?.processingFee
          ).toFixed(2);
          return numberFormatter(processingFee);
        }
      },
      color: "warning",
      customerVisibility: true,
    },
    {
      headerName: "Fiat delivered to user",
      width: "230",
      color: "warning",
      colId: "fiat.orderAmount",
      cellRenderer: (params) => {
        let processingFees;
        if (isEmpty(params.data?.fiat?.processingFee)) {
          const tfFee = Number(get(params.data, "fiat.tfFee", 0));
          const cxFee = Number(get(params.data, "fiat.cxFee", 0));
          const processingFee = (tfFee + cxFee).toFixed(2);
          processingFees = processingFee;
        } else {
          const processingFee = Number(
            params.data?.fiat?.processingFee
          ).toFixed(2);
          processingFees =  processingFee;
        }
          return numberFormatter(params.data?.fiatAmount - processingFees);
      },
      customerVisibility: true,
    },
    {
      headerName: "Customer Rev share",
      width: "150",
      colId: "fiat.cxfee",
      cellRenderer: (params) => {
        const discountRate = Number(get(params.data, "fiat.cxfee", 0));
        return numberFormatter(discountRate);
      },
      color: "warning",
      customerVisibility: true,
    }
  ];
  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      p={5}
    >
      {!isLoading ? (
        <Box>
          <HStack pb={5} justifyContent={"flex-end"}>
            <Button
              leftIcon={<BiReset style={{ fontSize: "20px" }} />}
              color={"#525C76"}
              variant={"outline"}
              borderColor={"#d2d2d2"}
              _hover={{ bg: "#ffffff" }}
              onClick={handleReset}
            >
              Reset
            </Button>
          </HStack>
          <GridV2
            columns={rampType === "buy" ? onRampColumns : offRampColumns}
            key={refreshGrid.toString()}
            getOrderData={getOrderData}
          />
        </Box>
      ) : (
        <Loader />
      )}
    </Stack>
  );
};

export default RampOrderV2;
