import React, { useState } from 'react';
import { Box, Collapse, Divider, Flex, Icon, Link, VStack, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import logo from '../../assets/transfi-main.svg'


import WarningModal from '../modal';
import { IoSettings } from "react-icons/io5";
import CustomBadge from './CustomBadge';
import TabChangeModal from '../modal/TabChangeModal';
import { resetState } from '../../store/slices/sendMoneySlices';

const SideDropdownNavItem = ({ title, children, path, icon }) => {
    const [isOpen, setIsOpen] = useState(false);
    const currentTab = window.location.pathname;
    const isSelected = currentTab.includes(path);
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Box>
            <Flex
                justify={'space-between'}
                cursor="pointer"
                onClick={handleToggle}
                padding={2}
                alignItems={'center'}
                p={' 15px 24px '}
                my="3"
                mx="4"
                borderRadius="lg"
                role="group"
                color={(isSelected && !isOpen) ? "#1A4FD6" : "#999999"}
                bg={(isSelected && !isOpen) ? "#E8EDFB" : ""}
                fontSize={"15px"}
                fontFamily={"'DM Sans', sans-serif"}
                fontWeight={'500'}
                _hover={{
                    bg: (isSelected && !isOpen) ? "#E8EDFB" : '#F2F2F2',
                    color: (isSelected && !isOpen) ? "#1A4FD6" : '#999999',
                }}
                transition={'0.2s ease-in-out'}
            >
                <Box display={'flex'} alignItems={'center'}
                    align="center">
                    <Icon as={icon} width={'20px'} height={'20px'} mr={'16px'} />
                    {title}
                </Box>
                {isOpen ? <Icon width={'20px'} height={'20px'} as={ChevronDownIcon} /> : <Icon width={'20px'} height={'20px'} as={ChevronRightIcon} />}
            </Flex>
            <Collapse in={isOpen} animateOpacity>
                <Box shadow="md" ml={'8'}>
                    <VStack align="stretch">
                        {children}
                    </VStack>
                </Box>
            </Collapse>
        </Box>
    );
};

const CollapsedNavItem = ({ name, icon, path }) => {
    const currentTab = window.location.pathname;
    const isSelected = currentTab.includes(path);
    const navigate = useNavigate();
    return (

        <Link onClick={() => {
            navigate(path);
        }
        } style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>

            <Box
                key={name}
                display="flex"
                cursor="pointer"
                padding={2}
                alignItems={'center'}
                align="center"
                p={' 15px 24px '}
                mx="4"
                borderRadius={"4px"}
                role="group"
                color={isSelected ? "#1A4FD6" : "#999999"}
                bg={isSelected ? "#E8EDFB" : ""}
                fontSize={"14px"}
                fontFamily={"'DM Sans', sans-serif"}
                fontWeight={'500'}
                _hover={{
                    bg: isSelected ? "#E8EDFB" : '#F2F2F2',
                    color: isSelected ? "#1A4FD6" : '#999999',
                }}
                transition={'0.2s ease-in-out'}

            >
                <Icon as={icon} width={'20px'} height={'20px'} mr={'16px'} />
                {name}
            </Box>
        </Link>
    )
}

export default function SideNav({ navItems, productType = "admin" }) {
    const approvalsList = useSelector((state) => state?.transaction?.list || []) || [];

    const payoutApprovalList = approvalsList?.filter((item) => (item?.type === "fiat_payout" || item?.type === "crypto_payout")) || [];
    const collectionsApprovalList = approvalsList?.filter((item) => (item?.type === "collection")) || [];

    const approvalBadgeCount = (productType === "payout") ? payoutApprovalList.length : (productType === "collection") ? collectionsApprovalList.length : approvalsList.length;

    return (
        <Box >
            <SidebarContent
                display={{ base: 'none', md: 'block' }}
                navItems={navItems}
                approvalBadgeCount={approvalBadgeCount}
            />
        </Box>
    );
}

const SidebarContent = ({ navItems, approvalBadgeCount = 0, ...rest }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const orgRoles = useSelector((state) => state?.login?.orgDetails?.orgRoles);
    const orgType = useSelector((state) => state?.login?.orgDetails?.orgType || "default");
    const currentTab = window.location.pathname;

    const isSelected = window.location.pathname.includes('/settings');

    const {
        onOpen: TabChangeModalOpen,
        onClose: TabChangeModalClose,
        isOpen: TabChangeModalIsOpen,
    } = useDisclosure();

    const confirmSendMoneyTabChange = (path) => {
        dispatch(resetState());
        navigate(path);
        TabChangeModalClose();
    }

    const handleTabClick = () => {
        if (currentTab === "/send-money/individual-payment") {
            TabChangeModalOpen();
        } else {
            navigate("/settings");
        }
    }

    return (
        <Box
            bg={'#FFFFFF'}
            w={'252px'}
            pos="fixed"
            h="full"
            overflowY={"auto"}
            overflowX={"hidden"}
            transition="3s ease"
            {...rest}
            borderRight={'1px'}
            borderRightColor={"#E4E4E4"}
        >
            <Flex h="20" alignItems="center" mx="10" justifyContent="space-between" mb={'20px'} >
                <img style={{ marginTop: '10px', width: '180px' }} src={logo} />
            </Flex>

            {navItems.map((link) => (
                ((orgRoles?.includes("transfi_admin")) || (link.customerVisibility)) && link.orgTypeVisibility ?
                    <>
                    {(link?.divider ?? false) && <Divider ml={'13px'} mb={'2'} w={220} />}
                        {link?.collapsable ?
                            <SideDropdownNavItem title={link.name} path={link.path} icon={link.icon}>
                                {link.children.map((child) =>
                                    <CollapsedNavItem name={child.name} icon={child.icon} path={child.path} />)}
                            </SideDropdownNavItem>
                            : <NavItem key={link.name} icon={link.icon} path={link?.path} hasBadge={link.hasBadge || false} badgeCount={(link.hasBadge || false) && approvalBadgeCount}>
                                {link.name}
                            </NavItem>}
                    </>
                    :
                    null
            ))}
            {["default", "admin", "payout", "collections"].includes(orgType) ?
                <div >
                    <Divider ml={'13px'} mb={'2'} w={220} />
                    <Flex
                        w={220}
                        align="center"
                        p={' 15px 24px '}
                        mx="4"
                        borderRadius={'4px'}
                        role="group"
                        cursor="pointer"
                        color={isSelected ? "#1A4FD6" : "#999999"}
                        bg={isSelected ? "#E8EDFB" : ""}
                        fontSize={"15px"}
                        fontFamily={"'DM Sans', sans-serif"}
                        fontWeight={'500'}
                        _hover={{
                            bg: isSelected ? "#E8EDFB" : '#F2F2F2',
                            color: isSelected ? "#1A4FD6" : '#999999',
                        }}
                        transition={'0.2s ease-in-out'}
                        onClick={handleTabClick}
                    >
                        <Icon as={IoSettings} mr="16px" width='20px' height='20px' />
                        {"Settings"}
                    </Flex>
                </div> :
                null
            }
            <TabChangeModal
                isOpen={TabChangeModalIsOpen}
                onClose={TabChangeModalClose}
                headingText={"Unsaved Changes"}
                bodyText={"There are some unsaved changes."}
                bodySubText={"Are you sure you want to exit?"}
                cancelBtnText={"Cancel"} confirmBtnText={"Exit"}
                onConfirm={() => confirmSendMoneyTabChange("/settings")} />
        </Box>
    );
};

const NavItem = ({ icon, children, path, hasBadge, badgeCount, ...rest }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentTab = window.location.pathname;
    const isSelected = currentTab.includes(path);
    const {
        onOpen: warnModalOpen,
        onClose: warnModalClose,
        isOpen: warmModalIsOpen,
    } = useDisclosure();
    const {
        onOpen: TabChangeModalOpen,
        onClose: TabChangeModalClose,
        isOpen: TabChangeModalIsOpen,
    } = useDisclosure();
    const orderStatus = useSelector((state) => state?.order?.status)

    const NavigateToPath = () => {
        if (orderStatus === 'draft') {
            warnModalOpen()
        } else if (currentTab === "/send-money/individual-payment") {
            TabChangeModalOpen(path);
        } else {
            console.log("Current path:", currentTab);
            navigate(path)
        }
    }

    const confirmSendMoneyTabChange = () => {
        dispatch(resetState());
        navigate(path);
        TabChangeModalClose();
    }

    return (
        <Link onClick={() => {
            if (path === "/api-docs") {
                const environment = process.env.REACT_APP_NODE_ENV;
                let prefix = "";
                if(environment === "qa"){
                    prefix = "qa-";
                }else if(environment === "development"){
                    prefix = "dev-";
                }
                return window.open(`https://${prefix}docs.transfi.com/`, '_blank');
            }
            NavigateToPath();
        }
        } style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                justify={'space-between'}
                p={' 15px 24px '}
                mx="4"
                my="3"
                borderRadius={"4px"}
                role="group"
                cursor="pointer"
                color={isSelected ? "#1A4FD6" : "#999999"}
                bg={isSelected ? "#E8EDFB" : ""}
                fontSize={"15px"}
                fontFamily={"'DM Sans', sans-serif"}
                fontWeight={'500'}
                _hover={{
                    bg: isSelected ? "#E8EDFB" : "#F2F2F2",
                    color: isSelected ? "#1A4FD6" : '#999999',
                }}
                transition={'0.2s ease-in-out'}
                {...rest}>
                <Flex align="center">
                    <Icon as={icon} width={'20px'} height={'20px'} mr={'16px'} />
                    {children}
                </Flex>
                {hasBadge && <CustomBadge>{badgeCount}</CustomBadge>}
            </Flex>

            <WarningModal path={path} isOpen={warmModalIsOpen} onClose={warnModalClose} />
            <TabChangeModal
                isOpen={TabChangeModalIsOpen}
                onClose={TabChangeModalClose}
                headingText={"Unsaved Changes"}
                bodyText={"There are some unsaved changes."}
                bodySubText={"Are you sure you want to exit?"}
                cancelBtnText={"Cancel"} confirmBtnText={"Exit"}
                onConfirm={confirmSendMoneyTabChange} />
        </Link>
    );
};
