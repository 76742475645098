import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { HStack, Stack, VStack } from '@chakra-ui/layout';
import { Checkbox, Flex, Text, Box } from '@chakra-ui/react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { Button } from '@chakra-ui/button';
import { useFormikContext } from "formik";
import { isEmpty, sortBy } from 'lodash';
import moment from 'moment';

import ThunderIcon from '../../../../assets/thunder.svg';

// Custom Function and Components
import Typography from '../../../../components/Common/Typography';
import Card from '../../../../components/Common/Card';
import { SelectMenu } from '../../../../components/Common/InputComponents/InputComponents';
import ContactService from '../../../../services/contactService';
import BalanceService from '../../../../services/balanceService';
import InfoIcon from '../../../../assets/InfoIcon2.svg';

// CSS
import '../../sendmoney.css';

const SelectContactTab = (props) => {
  const { country, settings, setBlockTxn, isOpen, onClose } = props;

  const navigate = useNavigate();
  const { setFieldValue, getFieldProps } = useFormikContext();

  const contactIdFieldValue = getFieldProps("contactId")?.value;
  const paymentAccountNameFieldValue = getFieldProps("paymentAccountName")?.value;

  const [formattedPaymentAccountList, setFormattedPaymentAccountList] = useState([]);
  const [paymentPurposeList, setPaymentPurposeList] = useState([
    {
      label: "Utility payment",
      value: "Utility payment"
    },
    {
      label: "Tax payment",
      value: "Tax payment"
    },
    {
      label: "Rental payment",
      value: "Rental payment"
    },
    {
      label: "Insurance payment",
      value: "Insurance payment"
    },
    {
      label: "Salary & wages",
      value: "Salary & wages"
    },
    {
      label: "Investment",
      value: "Investment"
    },
    {
      label: "Trade related payment",
      value: "Trade related payment"
    }
  ]);
  const [walletList, setWalletList] = useState([]);
  const [fiatWalletList, setFiatWalletList] = useState([]);
  const [cryptoWalletList, setCryptoWalletList] = useState([]);
  const [resetWallet, setResetWallet] = useState(false);
  const [resetPaymentAccount, setResetPaymentAccount] = useState(false);
  const [selfCertificationCheck, setSelfCertificatioCheck] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);
  const [paymentAccountLoading, setPaymentAccountLoading] = useState(false);

  const { data: contactList } = useSelector((state) => state.sendMoney?.contactList);

  const formattedContactList = contactList?.filter((item) => item?.status === "success")
    ?.map((item) => ({
      label: `${item?.firstName || ""} ${item?.lastName || ""}`,
      value: `${item?.recipientId}`,
      logo: item?.logo
    })) || [];

  const handleBack = () => {
    navigate(-1);
  };

  async function handleGetPaymentAccount(payload) {
    try {
      setWalletList([]);
      setPaymentAccountLoading(true);
      const { data } = await ContactService.getContact(payload) || {};
      const { paymentAccounts: paymentAccountList } = data?.data || {};
      const newState = paymentAccountList?.map((item) => {
        const additionalLabelValue = ["bank", "local_wallet"].includes(item?.type) ? (`(${item?.metaData?.bankName})`) : (`(${item?.currency} Wallet)`);

        return {
          key: `${item?.paId || ""}`,
          label: `${item?.displayName || ""} ${additionalLabelValue || ""}`,
          value: `${item?.paId || ""}`,
          logo: `${item?.logo || ""}`,
          type: `${item?.type || ""}`,
          currency: `${item?.currency || ""}`,
          counterPartyStatus: `${item?.counterPartyStatus || ""}`
        };
      });
      if (isEmpty(newState))
        setFormattedPaymentAccountList([]);
      else
        setFormattedPaymentAccountList(prev => newState);
    }
    catch (err) {
      console.log(err);
    } finally {
      setPaymentAccountLoading(false);
    }
  }

  function handleReset(fieldName) {
    if (fieldName === "contactId") {
      setFieldValue("paymentAccountName", "");
      setFieldValue("walletName", "");
      setResetWallet(prev => !prev);
      setResetPaymentAccount(prev => !prev);
    }
    else if (fieldName === "paymentAccountName") {
      setFieldValue("walletName", "");
      setResetWallet(prev => !prev);
    }
  }

  useEffect(() => {
    const handleGetBalance = async () => {
      try {
        setWalletLoading(true);
        const { data } = await BalanceService.getBalanceList() || {};
        const { response: balanceArray = [] } = data?.data || {};
        const formattedBalanceArray = balanceArray?.map((item) => {
          return {
            key: item.currency,
            label: `${item.currency}`,
            value: item.type,
            balance: item.balance.availableBalance,
            logo: item.logo
          };
        }) || [];
        const filteredFiatWallet = sortBy(formattedBalanceArray?.filter((item) => item.value === "fiat"), 'label');
        const filteredCryptoWallet = formattedBalanceArray?.filter((item) => item.value === "crypto");
        setFiatWalletList(filteredFiatWallet);
        setCryptoWalletList(filteredCryptoWallet);

        if (paymentAccountNameFieldValue?.value) {
          if (paymentAccountNameFieldValue.type === "crypto_wallet") {
            const finalWalletList = filteredCryptoWallet.concat(filteredFiatWallet);
            setWalletList(finalWalletList);
          }
          else if (["bank", "local_wallet"].includes(paymentAccountNameFieldValue?.type)) {
            setWalletList(filteredCryptoWallet);
          }
        }
      }
      catch (err) {
        console.log(err);
      } finally {
        setWalletLoading(false);
      }
    }
    handleGetBalance();
  }, [paymentAccountNameFieldValue]);

  useEffect(() => {
    if (contactIdFieldValue?.value) {
      handleGetPaymentAccount(contactIdFieldValue?.value);
    }
  }, [contactIdFieldValue, resetPaymentAccount]);

  useEffect(() => {
    if (paymentAccountNameFieldValue?.value) {
      if (paymentAccountNameFieldValue?.type === "crypto_wallet") {
        const finalWalletList = cryptoWalletList.concat(fiatWalletList);
        setWalletList(finalWalletList);
      }
      else if (["bank", "local_wallet"].includes(paymentAccountNameFieldValue?.type)) {
        setWalletList(cryptoWalletList);
      }
    }
  }, [paymentAccountNameFieldValue, resetWallet]);

  const selectedPaymentAccount = formattedPaymentAccountList.find(account => account.value === paymentAccountNameFieldValue?.value);
  const counterPartyStatus = selectedPaymentAccount?.counterPartyStatus;

  const checkBankingHours = () => {
    const currency = selectedPaymentAccount?.currency;
    const isOtcTxn = settings?.otcCurrencies?.includes(currency);
    const getCountry = country?.find(list => list.currency === currency)
    const { bankingHoursInUTC = {} } = getCountry;

    if (isEmpty(bankingHoursInUTC) || isEmpty(settings?.otcCurrencies) || isEmpty(bankingHoursInUTC)) {
      return;
    } else {
      const currentTime = moment.utc()
      const startTime = bankingHoursInUTC && moment.utc(bankingHoursInUTC?.startTime, "HH:mm");
      const endTime = bankingHoursInUTC && moment.utc(bankingHoursInUTC?.endTime, "HH:mm");
      // Check if the current time is within the working hours
      const isBankingHours = currentTime.isBetween(startTime, endTime, null, '[]');
      const blockTxn = !(isOtcTxn && isBankingHours);
      console.log(isOtcTxn, isBankingHours);
      setBlockTxn(blockTxn);
    }
  }

  useEffect(() => {
    selectedPaymentAccount && checkBankingHours();
  }, [selectedPaymentAccount]);

  return (
    <VStack w={"100%"} marginTop={"15px"} gap='24px'>
      <Card>
        <Typography type='body' weight='medium' color='primary' >
          Contact Details
        </Typography>
        <HStack w={'100%'} display='flex' justifyContent='space-between' gap="10px" zIndex={"100"} >
          <SelectMenu
            label={"Select Contact"}
            isMandatory={true}
            options={formattedContactList}
            name="contactId"
            placeholder="Select"
            searchBoxPlaceholder='Search Contact'
            optionBoxPlaceHolder='No contacts added'
            buttonPlaceholder='Add Contact'
            handleAddFunction={() => { navigate('/contact/add') }}
            isDisabled={false}
            onChange={(fieldName) => handleReset(fieldName)}
          />
          <SelectMenu
            label={"Payment purpose"}
            isMandatory={true}
            options={paymentPurposeList}
            name="paymentPurpose"
            placeholder="Select"
            searchBoxPlaceholder='Search Payment Purpose'
            optionBoxPlaceHolder='No purpose added'
            isDisabled={false}
          />
        </HStack>
        <HStack w={'100%'} display='block' gap="10px" >
          <SelectMenu
            label={"Select Account"}
            isMandatory={true}
            options={formattedPaymentAccountList}
            name="paymentAccountName"
            placeholder="Select"
            searchBoxPlaceholder='Search Account'
            optionBoxPlaceHolder='No account added'
            buttonPlaceholder='Add Account'
            handleAddFunction={() => { navigate(`/contact/edit/${contactIdFieldValue?.value}`) }}
            isDisabled={((contactIdFieldValue?.value || '') === '') || paymentAccountLoading}
            onChange={(fieldName) => handleReset(fieldName)}
          />
          {counterPartyStatus && (
            <HStack w={'100%'} display='flex' alignItems='center' marginTop='4px' gap="10px">
              {counterPartyStatus === 'pending' && (
                <>
                  <Box display="flex" width='386px' height='40px' gap='8px' alignItems="center" bg="#F2F2F2" p="8px" borderRadius="4px" padding='4px, 8px, 4px, 8px' >
                    <Box as="span" display="flex" alignItems="center" justifyContent="center" mr="4px">
                      <img
                        src={InfoIcon}
                        alt="info"
                        style={{ width: '20px', height: '20px' }}
                      />
                    </Box>
                    <Text fontWeight='medium' fontSize='sm' color='black'>
                      Payout will be released once counterparty creation is successful. Can take up to 1 working day.
                    </Text>
                  </Box>
                </>
              )}
              {counterPartyStatus === 'success' && (
                <>
                  <Box display="flex" alignItems="center" bg="#E9F6F2" p="8px" borderRadius="4px" height='20px' width="15%" padding='0px, 8px, 0px, 8px' gap='8px'>
                    <Box as="span" display="flex" alignItems="center" justifyContent="center" b>
                      <img
                        src={ThunderIcon}
                        alt="thunder"
                        style={{ width: '16px', height: '16px' }}
                      />
                    </Box>
                    <Text fontWeight='medium' fontSize='sm' color='green.500'>
                      Instant Payout
                    </Text>
                  </Box>
                </>
              )}
            </HStack>
          )}

        </HStack>
        <Flex gap={5}>
          <Checkbox
            isChecked={selfCertificationCheck}
            onChange={() => setSelfCertificatioCheck(!selfCertificationCheck)}
          />
          <VStack>
            <Typography type='body' style={{ color: "#999999" }} weight='regular' colorweight='800' >
              I confirm the account details are accurate and match the contact's information. I intend to send money to above mentioned contact and account details.
            </Typography>
          </VStack>
        </Flex>
      </Card>
      <Card>
        <Typography type='body' weight='medium' color='primary' >
          Payment details
        </Typography>
        <HStack w={'100%'} display='flex' justifyContent='space-between' gap="10px" >
          <SelectMenu
            label={"You Pay From"}
            isMandatory={true}
            options={walletList}
            name="walletName"
            placeholder="Select wallet"
            searchBoxPlaceholder='Search Wallet'
            optionBoxPlaceHolder='No Wallet added'
            isDisabled={((paymentAccountNameFieldValue?.value || "") === "") || walletLoading}
          />
        </HStack>
        <Stack direction="row" justifyContent={"end"} w='100%' marginTop={0}>
          <Button variant={"secondary"} size={"sm"} type="button" p={"9px 16px 9px 16px"} onClick={handleBack}>
            Back
          </Button>
          <Button isDisabled={!selfCertificationCheck} variant={"primary"} type="submit" size={"sm"} p={"9px 16px 9px 16px"}>
            Next
          </Button>
        </Stack>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack justify="center" textAlign="center" color="#1A4FD6">
              <Text>Notice on orders!</Text>
            </HStack>
          </ModalHeader>
          <ModalBody textAlign="center">
            <Text >We accept orders only during banking hours:</Text>
            <Text fontWeight={"bold"} mb={5}>8:30 AM - 1:00 PM (Colombia Time)</Text>
            <Text>Please come back and place your order the next business day.</Text>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <HStack>
              <Button
                border="1px"
                borderColor="#525C76"
                variant={"primary"}
                onClick={onClose}
              >
                Close
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}

export default SelectContactTab;
